const {
  generateDependenciesToken
} = require('@wix/cloud-user-code-dependencies-token-generator')
const { enrichUrl } = require('./enrichUrl')

const createUserCodeMapWithEnrichedUrls = ({
  userCodeMap,
  codePackagesData,
  shouldUseDependenciesForBundle
}) => {
  if (!codePackagesData || !shouldUseDependenciesForBundle) {
    return userCodeMap
  }

  const codePackagesObject = codePackagesData.reduce((acc, codePackage) => {
    acc[codePackage.importName] = codePackage.gridAppId
    return acc
  }, {})

  const dependenciesToken = generateDependenciesToken(codePackagesObject)

  return userCodeMap.map(userCodeData => {
    return {
      ...userCodeData,
      url: enrichUrl(userCodeData.url, {
        'dependencies-token': dependenciesToken
      })
    }
  })
}

module.exports = {
  createUserCodeMapWithEnrichedUrls
}
